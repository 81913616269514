export default[
    
    {
      path: '/asb_kewajaran_belanja',
      name: '/asb_kewajaran_belanja',
      component: () => import('@/views/asb_kewajaran_belanja/Index.vue'),
      children: [
        {
          path: '/asb_kewajaran_belanja/list_dinas',
          name: '/asb_kewajaran_belanja/list_dinas',
          component: () => import('@/views/asb_kewajaran_belanja/list_dinas/Index.vue'),
          meta: {
            pageTitle: 'ASB Kesesuaian Belanja',
            breadcrumb: [ 
              {
                text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
                active: true,
              }, 
            ],
          }
        },
        {
          path: '/asb_kewajaran_belanja/detail_dinas',
          name: '/asb_kewajaran_belanja/detail_dinas',
          component: () => import('@/views/asb_kewajaran_belanja/detail_dinas/Index.vue'),
          children: [
            {
              path: '/asb_kewajaran_belanja/detail_dinas/dashboard',
              name: '/asb_kewajaran_belanja/detail_dinas/dashboard',
              component: () => import('@/views/asb_kewajaran_belanja/detail_dinas/asb/Index.vue'),
              meta: {
                pageTitle: 'ASB Kesesuaian Belanja',
                breadcrumb: [ 
                  {
                    text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
                    active: true,
                  }, 
                ],
              }
            } , 
            {
              path: '/asb_kewajaran_belanja/detail_dinas/asb',
              name: '/asb_kewajaran_belanja/detail_dinas/asb',
              component: () => import('@/views/asb_kewajaran_belanja/detail_dinas/asb/Index.vue'),
              meta: {
                pageTitle: 'ASB Kesesuaian Belanja',
                breadcrumb: [ 
                  {
                    text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
                    active: true,
                  }, 
                ],
              }
            } 
          ],
          meta: {
            pageTitle: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
            breadcrumb: [ 
              {
                text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
                active: true,
              }, 
            ],
          }
        } ],
      meta: {
        pageTitle: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
        breadcrumb: [ 
          {
            text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/asb_kewajaran_belanja/tambah_kegiatan',
      name: '/asb_kewajaran_belanja/tambah_kegiatan',
      component: () => import('@/views/asb_kewajaran_belanja/detail_kegiatan/Index.vue'),
      meta: {
        pageTitle: 'ASB Kesesuaian Belanja',
        breadcrumb: [ 
          {
            text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
            active: true,
          }, 
        ],
      }
    } 
    ,{
      path: '/asb_kewajaran_belanja/detail_kegiatan',
      name: '/asb_kewajaran_belanja/detail_kegiatan',
      component: () => import('@/views/asb_kewajaran_belanja/detail_kegiatan/Index.vue'),
      meta: {
        pageTitle: 'ASB Kesesuaian Belanja',
        breadcrumb: [ 
          {
            text: 'ASB Kesesuaian Rencana Kerja dan Anggaraan',
            active: true,
          }, 
        ],
      }
    } ,
    {
      path: '/laporan_belanja',
      name: '/laporan_belanja',
      component: () => import('@/views/laporan_belanja/index.vue'),
      meta: {
        pageTitle: 'Laporan Belanja',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    }, 
     
]