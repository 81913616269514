export default[
    
    {
      path: '/asb_kesesuaian_ssh',
      name: '/asb_kesesuaian_ssh',
      component: () => import('@/views/asb_kesesuaian_ssh/Index.vue'),
      children: [
        {
          path: '/asb_kesesuaian_ssh/list_dinas',
          name: '/asb_kesesuaian_ssh/list_dinas',
          component: () => import('@/views/asb_kesesuaian_ssh/list_dinas/Index.vue'),
          meta: {
            pageTitle: 'ASB Kesesuaian Standar Satuan Harga',
            breadcrumb: [ 
              {
                text: 'ASB Kesesuaian Standar Satuan Harga',
                active: true,
              }, 
            ],
          }
        },
        {
          path: '/asb_kesesuaian_ssh/detail_dinas',
          name: '/asb_kesesuaian_ssh/detail_dinas',
          component: () => import('@/views/asb_kesesuaian_ssh/detail_dinas/Index.vue'),
          children: [
            {
              path: '/asb_kesesuaian_ssh/detail_dinas/sub_kegiatan',
              name: '/asb_kesesuaian_ssh/detail_dinas/sub_kegiatan',
              component: () => import('@/views/asb_kesesuaian_ssh/detail_dinas/sub_kegiatan/Index.vue'),
              meta: {
                pageTitle: 'ASB Kesesuaian Standar Satuan Harga',
                breadcrumb: [ 
                  {
                    text: 'ASB Kesesuaian Standar Satuan Harga',
                    active: true,
                  }, 
                ],
              }
            },{
              path: '/asb_kesesuaian_ssh/detail_dinas/detail_kegiatan',
              name: '/asb_kesesuaian_ssh/detail_dinas/detail_kegiatan',
              component: () => import('@/views/asb_kesesuaian_ssh/detail_kegiatan/Index.vue'),
              meta: {
                pageTitle: 'ASB Kesesuaian Standar Satuan Harga',
                breadcrumb: [ 
                  {
                    text: 'ASB Kesesuaian Standar Satuan Harga',
                    active: true,
                  }, 
                ],
              }
            } 
          ],
          meta: {
            pageTitle: 'ASB Kesesuaian Standar Satuan Harga',
            breadcrumb: [ 
              {
                text: 'ASB Kesesuaian Standar Satuan Harga',
                active: true,
              }, 
            ],
          }
        } ],
      meta: {
        pageTitle: 'ASB Kesesuaian Standar Satuan Harga',
        breadcrumb: [ 
          {
            text: 'ASB Kesesuaian Standar Satuan Harga',
            active: true,
          },
        ],
      },
    }, {
      path: '/asb_kesesuaian_ssh/detail_kegiatan',
      name: '/asb_kesesuaian_ssh/detail_kegiatan',
      component: () => import('@/views/asb_kesesuaian_ssh/detail_kegiatan/Index.vue'),
      meta: {
        pageTitle: 'ASB Kesesuaian Standar Satuan Harga',
        breadcrumb: [ 
          {
            text: 'ASB Kesesuaian Standar Satuan Harga',
            active: true,
          }, 
        ],
      }
    } ,
     
]