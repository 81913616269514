export default[
  
    {
      path: '/registrasi',
      name: 'registrasi',
      component: () => import('@/views/registrasi/Index.vue'), 
      meta: {
        layout: 'full',
        pageTitle: 'Registrasi Akun Jala Pusaka',
        breadcrumb: [ 
          {
            text: 'Rrgistrasi',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/registrasi_info',
      name: 'registrasi_info',
      component: () => import('@/views/registrasi/RegistrasiInfo.vue'), 
      meta: {
        layout: 'full', 
      },
    }, 
    {
        path: '/lupa_password',
        name: 'lupa_password',
        component: () => import('@/views/registrasi/LupaPassword.vue'),
        meta: {
            layout: 'full',
          pageTitle: 'Lupa Password Akun Jala Pusaka',
          breadcrumb: [ 
            {
              text: 'Lupa Password',
              active: true,
            },
          ],
        },
      }, 
]