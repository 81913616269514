import Vue from 'vue'
import VueRouter from 'vue-router'
import acl from './routers/acl'
import error from './routers/error'
import home from './routers/home'
import profile from './routers/profile'
import registrasi from './routers/registrasi'
import input_rka from './routers/input_rka'
import kesesuaian_belanja from './routers/kesesuaian_belanja'
import kesesuaian_ssh from './routers/kesesuaian_ssh'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...acl,
    ...error,
    ...home,
    ...profile,
    ...registrasi, 
    ...input_rka,
    ...kesesuaian_belanja,
    ...kesesuaian_ssh
  ],
}) 
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})



export default router
