export default[
    
    // {
    //   path: '/input',
    //   name: '/input',
    //   component: () => import('@/views/input/Index.vue'),
    //   children: [
    //     {
    //       path: '/input/list_dinas',
    //       name: '/input/list_dinas',
    //       component: () => import('@/views/input/list_dinas/Index.vue'),
    //       meta: {
    //         pageTitle: 'Input Rencana Kerja dan Anggaraan ',
    //         breadcrumb: [ 
    //           {
    //             text: 'Rencana Kerja dan Anggaraan',
    //             active: true,
    //           }, 
    //         ],
    //       }
    //     },
    //     {
    //       path: '/input/detail_dinas',
    //       name: '/input/detail_dinas',
    //       component: () => import('@/views/input/detail_dinas/Index.vue'),
    //       children: [
    //         {
    //           path: '/input/detail_dinas/sub_kegiatan',
    //           name: '/input/detail_dinas/sub_kegiatan',
    //           component: () => import('@/views/input/detail_dinas/sub_kegiatan/Index.vue'),
    //           meta: {
    //             pageTitle: 'Input Rencana Kerja dan Anggaraan ',
    //             breadcrumb: [ 
    //               {
    //                 text: 'Rencana Kerja dan Anggaraan',
    //                 active: true,
    //               }, 
    //             ],
    //           }
    //         } ,
    //         {
    //           path: '/input/detail_dinas/program',
    //           name: '/input/detail_dinas/program',
    //           component: () => import('@/views/input/detail_dinas/program/Index.vue'),
    //           meta: {
    //             pageTitle: 'Input Rencana Kerja dan Anggaraan ',
    //             breadcrumb: [ 
    //               {
    //                 text: 'Rencana Kerja dan Anggaraan',
    //                 active: true,
    //               }, 
    //             ],
    //           }
    //         } ,
    //         {
    //           path: '/input/detail_dinas/asb',
    //           name: '/input/detail_dinas/asb',
    //           component: () => import('@/views/input/detail_dinas/asb/Index.vue'),
    //           meta: {
    //             pageTitle: 'Input Rencana Kerja dan Anggaraan ',
    //             breadcrumb: [ 
    //               {
    //                 text: 'Rencana Kerja dan Anggaraan',
    //                 active: true,
    //               }, 
    //             ],
    //           }
    //         }  
    //       ],
    //       meta: {
    //         pageTitle: 'Rencana Kerja dan Anggaraan',
    //         breadcrumb: [ 
    //           {
    //             text: 'Rencana Kerja dan Anggaraan',
    //             active: true,
    //           }, 
    //         ],
    //       }
    //     } ],
    //   meta: {
    //     pageTitle: 'Rencana Kerja dan Anggaraan',
    //     breadcrumb: [ 
    //       {
    //         text: 'Rencana Kerja dan Anggaraan',
    //         active: true,
    //       },
    //     ],
    //   },
    // },{
    //   path: '/input/tambah_kegiatan',
    //   name: '/input/tambah_kegiatan',
    //   component: () => import('@/views/input/tambah_kegiatan/Index.vue'),
    //   meta: {
    //     pageTitle: 'Input Rencana Kerja dan Anggaraan ',
    //     breadcrumb: [ 
    //       {
    //         text: 'Rencana Kerja dan Anggaraan',
    //         active: true,
    //       }, 
    //     ],
    //   }
    // } ,{
    //   path: '/input/detail_kegiatan',
    //   name: '/input/detail_kegiatan',
    //   component: () => import('@/views/input/detail_kegiatan/Index.vue'),
    //   meta: {
    //     pageTitle: 'Input Rencana Kerja dan Anggaraan ',
    //     breadcrumb: [ 
    //       {
    //         text: 'Rencana Kerja dan Anggaraan',
    //         active: true,
    //       }, 
    //     ],
    //   }
    // } ,
     
]